<template>
  <div class="container">
    <!-- <div class="item flex" v-for="item in 20" :key="item">
			{{ item }}
		</div> -->
    <div class="max-480 mobie">
      <div class="bg">
        <img src="../../assets/images/mobie/login/bg.png" alt="" />
        <div class="bg-text">
          <p>北斗电子浮标</p>
          <p>管理平台</p>
        </div>
      </div>
      <div class="form">
        <div class="form-content flex-dc">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="username">
              <el-input :placeholder="$t('label.login.account')" v-model="form.username" maxlength="15"
                @click.native="handleClick('acc')">
                <i class="iconfont icon-yonghu icon_margin" :style="activeInput == 'acc' ? 'color: #539D56;' : ''"
                  slot="prefix">
                </i>
              </el-input>
              <!-- <el-input v-model="username" placeholder="请输入用户名">
                <i class="iconfont icon-yonghu" slot="prefix"> </i>
              </el-input> -->
            </el-form-item>
            <el-form-item prop="password">
              <!-- :type="showPassword ? 'text' : 'password'" -->
              <el-input :placeholder="$t('label.login.password')" v-model="form.password" show-password maxlength="15"
                @click.native="handleClick('pwd')">
                <i class="iconfont icon-mima icon_margin" :style="activeInput == 'pwd' ? 'color: #539D56;' : ''"
                  slot="prefix">
                </i>
              </el-input>
              <!-- <el-input v-model="username" placeholder="请输入密码" show-password @click.native="handleClick">
                <i
                  class="iconfont icon-mima"
                  slot="prefix"
                >
                </i>
              </el-input> -->
            </el-form-item>
            <el-form-item prop="verification">
              <div style="display: flex; justify-content: space-between">
                <el-input style="width: 220px" class="verification" :placeholder="$t('label.login.ver_placeholder')"
                  v-model="form.verification" maxlength="15" @click.native="handleClick('ver')">
                  <i class="iconfont icon-idcard icon_margin" :style="activeInput == 'ver' ? 'color: #539D56;' : ''"
                    slot="prefix">
                  </i>
                </el-input>
                <div class="code" @click="getCaptchaURL()" :style="{
                  backgroundImage: `url(${codeURL})`,
                  backgroundSize: '100% 100%',
                }"></div>
              </div>
            </el-form-item>
          </el-form>
          <el-button class="form-btn" block type="primary" :loading="loading" @click="doLogin">{{
            $t("label.login.login_btn") }}</el-button>
        </div>
      </div>
    </div>
    <div class="min-480 flex pc">
      <div class="bg">
        <img src="../../assets/images/pc/login/bg.png" alt="" />
      </div>
      <div class="form flex-dc">
        <img src="../../assets/images/pc/login/title.png" alt="" />
        <div class="form-content flex-dc">
          <h3>{{ $t("label.login.user_login") }}</h3>
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="username">
              <el-input :placeholder="$t('label.login.account')" v-model="form.username" maxlength="15"
                @click.native="handleClick('acc')">
                <i class="iconfont icon-yonghu icon_margin" :style="activeInput == 'acc' ? 'color: #539D56;' : ''"
                  slot="prefix">
                </i>
              </el-input>
              <!-- <el-input v-model="username" placeholder="请输入用户名">
                <i class="iconfont icon-yonghu" slot="prefix"> </i>
              </el-input> -->
            </el-form-item>
            <el-form-item prop="password">
              <!-- :type="showPassword ? 'text' : 'password'" -->
              <el-input :placeholder="$t('label.login.password')" v-model="form.password" show-password maxlength="15"
                @click.native="handleClick('pwd')">
                <i class="iconfont icon-mima icon_margin" :style="activeInput == 'pwd' ? 'color: #539D56;' : ''"
                  slot="prefix">
                </i>
              </el-input>
              <!-- <el-input v-model="username" placeholder="请输入密码" show-password @click.native="handleClick">
                <i
                  class="iconfont icon-mima"
                  slot="prefix"
                >
                </i>
              </el-input> -->
            </el-form-item>
            <el-form-item prop="verification">
              <div style="display: flex; justify-content: space-between">
                <el-input style="width: 220px" :placeholder="$t('label.login.ver_placeholder')"
                  v-model="form.verification" maxlength="15" @click.native="handleClick('ver')">
                  <i class="iconfont icon-idcard icon_margin" :style="activeInput == 'ver' ? 'color: #539D56;' : ''"
                    slot="prefix">
                  </i>
                </el-input>
                <div class="code" @click="getCaptchaURL()" :style="{
                  backgroundImage: `url(${codeURL})`,
                  backgroundSize: '100% 100%',
                }"></div>
              </div>
            </el-form-item>
          </el-form>
          <el-button class="form-btn" block type="primary" :loading="loading" @click="doLogin">{{
            $t("label.login.login_btn") }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accountRegex, pwdRegex } from "../../utils/regExp";
import { getCookie, setCookie, delCookie } from "@/utils/cookie";
import { login, getCaptcha } from "@/api/index";
export default {
  data() {
    let validValueNumAndLetter = (rule, value, callback) => {
      const { field } = rule;
      let error = {
        username: this.$t("label.login.account"),
        password: this.$t("label.login.password"),
        verification: this.$t("label.login.verification"),
      };
      if (!value) {
        callback(
          new Error(`${error[field]}${this.$t("message.login.notNull")}`)
        );
      } else if (!this.$tool.regExp.validValueNumAndLetter(value)) {
        callback(
          new Error(
            `${error[field]}${this.$t("message.login.validValueNumAndLetter")}`
          )
        );
      } else {
        callback();
      }
    };
    return {
      verId: "",
      codeURL: "",
      activeInput: "",
      loading: false,
      form: {
        username: "",
        password: "",
        verification: "",
      },
      showPassword: false,
      routes: [
        // {
        //   id: 1,
        //   pid: "receiver",
        //   title: "接收机信息",
        //   route: "info",
        //   icon: "",
        //   sort: 2,
        //   hidden: false,
        //   isDetail: false,
        // },
        // {
        //   id: 2,
        //   pid: "status",
        //   title: "测试",
        //   route: "locationInfo",
        //   icon: "",
        //   sort: 2,
        //   hidden: false,
        //   isDetail: false,
        // },
        // {
        //   id: 3,
        //   pid: "",
        //   title: "测试",
        //   route: "updata",
        //   icon: "",
        //   sort: 2,
        //   hidden: false,
        //   isDetail: false,
        // },
        // {
        // 	id: 3,
        // 	pid: "",
        // 	title: "其他",
        // 	route: "other",
        // 	icon: "111111",
        // 	oneChildrenHidden: false,
        // 	sort: 1,
        // 	hidden: false,
        // 	isDetail: false,
        // },
        // {
        // 	id: 4,
        // 	pid: "3",
        // 	title: "登录设置",
        // 	route: "loginSet",
        // 	icon: "111111",
        // 	sort: 1,
        // 	hidden: false,
        // 	isDetail: false,
        // },
      ],
      rules: {
        username: [{ validator: validValueNumAndLetter, trigger: "blur" }],
        password: [{ validator: validValueNumAndLetter, trigger: "blur" }],
        verification: [{ validator: validValueNumAndLetter, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getCaptchaURL();
  },
  methods: {
    getCaptchaURL() {
      getCaptcha().then((res) => {
        this.codeURL = res.data.captcha;
        this.verId = res.data.id;
      });
    },
    // 登录方法
    async doLogin() {
      // 动态路由添加
      let param = {
        account: this.form.username,
        password: this.form.password,
        verify_id: this.verId,
        verify_value: this.form.verification,
      };
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // await this.$router.selfAddRoutes(this.routes);
          login(param).then(async(res) => {
            if (res.code === 200) {
              await this.$router.selfAddRoutes(this.routes); // 
              localStorage.setItem("token", JSON.stringify(res.data.token_info.token));
              localStorage.setItem("username", JSON.stringify(this.form.username));
              this.$store.commit('SET_ACCOUNT', this.form.username)
              this.$store.dispatch("login").then((res) => {
                this.$store.commit("SET_LOGIN_STATUS", true);
                this.$router.push("/");
              });
            } else {
              this.getCaptchaURL();
              return 
            }
          });
          this.form.verification = "";
          this.getCaptchaURL();
        } else {
          return false;
        }
      });
    },
    // 密码是否可见
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleClick(type) {
      this.activeInput = type;
      //   console.log("222");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background-color: #f7f7f7;
  // overflow-x: hidden;
  // overflow-y: auto;
  // .item {
  // 	height: 400px;
  // 	justify-content: center;

  // 	&:nth-child(2n) {
  // 		background-color: $main_color;
  // 	}
  // 	&:nth-child(2n + 1) {
  // 		background-color: $nav_active_bg;
  // 	}
  // }
  // & > div {
  // 	overflow-x: hidden;
  // 	overflow-y: auto;
  // }
  .bg {
    &>img {
      width: 100%;
      height: auto;
    }
  }

  .form-btn {
    border-color: $main_color;
    background-color: $main_color;
    font-weight: bold;
  }

  .mobie {
    height: 100%;

    .bg {
      position: relative;

      &-text {
        position: absolute;
        max-width: 248px;
        right: 58px;
        top: 60%;
        transform: translateY(-50%);
        text-align: center;
        // right: 0%;
      }

      & p {
        word-break: keep-all;
        font-size: 34px;
        font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
        font-weight: bold;
        color: #539D56;
        line-height: 48px;
        display: inline-block;
        text-shadow: 0px 2px 20px rgba(206, 234, 243, 0.5);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .form {
      padding-bottom: 50px;

      &::v-deep .el-input__prefix {
        left: 0;
        width: 35px;
        font-size: 18px;
      }

      // &::v-deep .el-input--prefix .el-input__inner {
      //   // padding-left: 25px;
      // }
      &::v-deep .el-form-item {
        border-bottom: 1px solid #d9d9d9;
        width: 280px;
      }

      &::v-deep .el-form-item:active {
        border-color: $main_color;
      }

      &::v-deep .el-input__inner {
        border-radius: 0px;
        border: none;
        background-color: transparent;
        padding-left: 35px;
      }

      .verification {
        &::v-deep .el-input__inner {
          padding: 0 35px;
        }
      }

      &-content {
        padding-top: 40px;
        align-items: center;
      }

      &-btn {
        width: 280px;
        margin-top: 38px;
      }
    }
  }

  .pc {
    height: 100%;
    background: linear-gradient(90deg, #f1f1f1 0%, #ffffff 100%);
    padding: 16px 50px;
    justify-content: space-between;

    .bg {
      width: 60%;
      max-width: 900px;
      margin-right: 50px;
    }

    .form {
      flex: 1;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 380px;

      &::v-deep .el-input__inner:focus {
        border-color: #539d56;
      }

      &>img {
        max-width: 400px;
        width: 100%;
        height: auto;
        display: block;
      }

      &-content {
        width: 380px;
        // min-width: 480px;
        margin-top: 10px;
        background: #ffffff;
        box-shadow: 0px 4px 22px 0px rgba(0, 30, 1, 0.08);
        padding: 0 24px 10px 24px;

        &>h3 {
          padding: 24px 0;
          border-bottom: 2px solid $main_color;
          margin-bottom: 50px;
        }

        &>p {
          text-align: right;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          font-size: 14px;
          line-height: 22px;
          text-shadow: 0px 4px 22px rgba(0, 30, 1, 0.08);
          padding: 24px 0;
        }
      }

      &-btn {
        margin: 15px 0 48px 0;
      }
    }
  }
}

.code {
  width: 100px;
  height: 40px;
  border: 1px solid #eee;
  // background-color: #eee;
  cursor: pointer;
}

.icon_margin {
  margin-left: 5px;
}
</style>